import React, {useCallback, useState} from "react"
import Modal from "../../../../components/utility/Modal/Modal";
import WindSelect from "../../../../components/formComponents/WindSelect/WindSelect";
import { getNotifier } from "../../../../services/notifier";
import "./ImpersonateFormModal.scss"

const ImpersonateFormModal = ({onClose, fetchedUsers}) => {

    const [impersonatedUser, setImpersonatedUser] = useState(null)
    const close = useCallback(() => {
        if (onClose)
            onClose()
    }, [onClose]);

    const login = () => {
        if(impersonatedUser){
            sessionStorage.setItem('impersonatedUser', impersonatedUser.value);
            window.location.reload();
        }else{
            getNotifier().warning("Selecciona un usuario para iniciar sesión")
        }
        
    }

    const options = fetchedUsers.map((item) => ({label: item.username.toUpperCase(), value: item.username }))

    const handleUserChange = (e) => {
        setImpersonatedUser(e)
    } 

    return(

        <Modal
        title="Inicio de sesión"
        onClose={close}
        secondaryButtonAction={close}
        mainButtonText="Iniciar sesión"
        mainButtonAction={ login }>
            <p>Seleccione el usuario con el que desea iniciar sesión</p>
            <WindSelect
                label="Usuario"
                name="user"
                options={options}
                onChange={handleUserChange}
                value={impersonatedUser}
            />

        </Modal>
    )
}

export default ImpersonateFormModal;