import React, {useEffect, useMemo} from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Api from 'tide-api';
import apiConfig from './services/api/api-config'
import {ApiContext} from "./services/api/api-config";
import './assets/fonts/fonts.scss';
import './assets/styles/App.scss';
import 'animate.css';
import {LOGIN_STATE} from "tide-api";
import store from "./services/redux/store";
import {useSelector} from "react-redux";
import Splash from "./components/Splash";
import SecurityManager, {SecurityContext} from "./services/SecurityManager";
import getAppRoutes from "./services/routes/appRoutes";
import notLoggedRoutes, { paths } from "./services/routes/notLoggedRoutes";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import TideToaster from "./components/utility/TideToaster/TideToaster";
import {notifierRef} from "./services/notifier";
import TideTooltiper from "./components/utility/TideTooltiper/TideTooltiper";
import MainLoadingIndicator from "./components/layout/MainLoadingIndicator/MainLoadingIndicator";
import _ from 'lodash';
import { refreshToken } from './services/refreshToken';
import NavBar from './components/utility/NavBar/NavBar';
import Modal from './components/utility/Modal/Modal';
import LoginModalForm from './components/LoginModalForm/LoginModalForm';

const api = new Api({...apiConfig, reduxStore: store});
let initialUrl = window.location.pathname + window.location.search;
let loggedInOnLoad = undefined;


function App() {

    const loggedIn=useSelector(({api})=>api.loggedIn===LOGIN_STATE.LOGGED_IN);

    // ------------ Login redirect ------------
    if( typeof loggedInOnLoad === 'undefined' ){
        loggedInOnLoad = loggedIn;
    }
    useEffect(()=>{
        if(!loggedInOnLoad && loggedIn){
            window.location.href = initialUrl;
        }
    },[loggedIn]);

    const loading=useSelector(({loadingIds})=>!!loadingIds['Initializing.me']);
    const me=useSelector(({api})=>api.me);

    useEffect(()=>{
        if(!me && !loading && loggedIn){
            api.me.get({loadingId:'Initializing.me', customProp:'me'}).catch(()=>api.logout());

            refreshToken(api);
        }
    },[me, loading, loggedIn]);

    const securityManager=useMemo(()=> me? new SecurityManager(me) : null,[me]);

    const routes= loggedIn && me?
        getAppRoutes(securityManager)
        :notLoggedRoutes;

    const flattenedRoutes = useMemo(() => {
        return _.flattenDeep(_.map(routes, route => {
            if (route.nestedRoutes) {
                return [route, ...route.nestedRoutes];
            }
            return route;
        }));
    }, [routes]);

    const splash=loading || (loggedIn && !me);

    useEffect(() => {
        if (!loggedIn) return;
    
        // Check if the script is already added
        if (!document.querySelector('script[src="https://app.getbeamer.com/js/beamer-embed.js"]')) {
            const beamerConfigScript = document.createElement('script');
            beamerConfigScript.innerHTML = `
                var beamer_config = {
                    product_id: 'hjnRfXDb68884'
                };
            `;
            document.body.appendChild(beamerConfigScript);
    
            const beamerScript = document.createElement('script');
            beamerScript.src = "https://app.getbeamer.com/js/beamer-embed.js";
            beamerScript.defer = true;
            document.body.appendChild(beamerScript);
        }
    
        return () => {
            const beamerConfigScript = document.querySelector('script:not([src])[innerHTML*="beamer_config"]');
            if (beamerConfigScript) document.body.removeChild(beamerConfigScript);
    
            const beamerScript = document.querySelector('script[src="https://app.getbeamer.com/js/beamer-embed.js"]');
            if (beamerScript) document.body.removeChild(beamerScript);
        };
    }, [loggedIn]);
    
    return (
        <div className="App">
            <TideToaster ref={notifierRef}/>
            <SecurityContext.Provider value={securityManager}>
                <ApiContext.Provider value={api} >
                    <ErrorBoundary>
                        <TideTooltiper />
                        <Router>
                            
                            <div className="wind-scene-content">
                                {loggedIn && <NavBar/>}
                                {splash ?
                                    <Splash/>
                                    :
                                    <Switch>
                                        
                                        {flattenedRoutes.map(route =>
                                            <Route key={route.path} path={route.path} component={route.component}
                                            exact={route.exact !== false} />
                                        )}
                                       

                                        {(!loggedIn && api.token) ?
                                            <Modal
                                            className="login-modal"
                                                onClose={() => window.location.href = paths.login}
                                            >
                                                <LoginModalForm/>
                                            </Modal>
                                            :
                                            <Redirect from='/' to={flattenedRoutes[0].path}/>}
                                    </Switch>
                                }
                            </div>
                            <MainLoadingIndicator />
                        </Router>
                    </ErrorBoundary>
                </ApiContext.Provider>
            </SecurityContext.Provider>
        </div>
    );
}

export default App;
